


import React, { useEffect,useContext, useState } from 'react';

import { BoxWrapper } from '../../../style';
import arrowIcon from "../../../images/arrow.png"
import { FormWrapper } from '../../../components/loan/style';
import Button from '../../../components/ui/Button';
import Alert from '../../../components/ui/Alert';
import {getDashboardData, savePerssonalDetails, verifyPan } from '../../../Utils/api';
import { useNavigate } from 'react-router-dom';
import { formateDate, getStorage, goBack, isAlphabet, isEmpty, isNumber, setStorage } from '../../../Utils/common';
import Input from '../../../components/ui/Input';
import Select from '../../../components/ui/Select';
import { formValidation } from '../../../Utils/formValidation';
import RadioButtons from '../../../components/ui/RadioButtons';
import ContextDashboard from '../../../Context/ContextDashboard';

const initialData = {
    first_name:"",
    dob:"",
    maritalStatus:"",
    email:"",
    gender:"",
}

const options =[
    {
        label:"Male",
        value:"MALE",
        name:"gender",
    },
    {
        label:"Female",
        value:"FEMALE",
        name:"gender"
    },
   
]
const marriedOptions =[
    {
      label:"Single",
      value:"1",
     
    },
    {
        label:"Married",
        value:"2",
       
    },
    {
      label:"Divorced",
      value:"3",
     
  },
   
]

function CapturPersonalInformation() {
    const [loading, setLoading] = useState(false);
    const [responce,setResponce] = useState({});
    const [formData, setFormData] = useState(initialData);
    const [formDataError, setFormDataError] = useState(initialData);

    const navigate = useNavigate();


    const {message,setMessage,logout} = useContext(ContextDashboard);

    const submit = () =>{
     

        const error = formValidation(formData);

        setFormDataError({...formDataError,...error});
        const param = {
          lead_id:getStorage("lead_id") || "",
          token:getStorage("token") || "",
          gender:formData.gender,
          email:formData.email,
          dob:formateDate(formData.dob),
          latLong:"0.0,0.0",
          marital_status:formData.maritalStatus,
          spouse_mobile:"",
          spouse_name:"",
          first_name:formData.first_name,
      }

        if(isEmpty(error)){
           setLoading(true);
        savePerssonalDetails(param).then(resp=>{
        setLoading(false);
        console.log(resp?.data)
        if(resp?.data?.Status === 1){
          setResponce(resp?.data);
          setMessage({ type: 'success', msg:resp?.data?.Message, place:"globle" });
          navigate("/my-dashboard/captur-income-details")
        }else if(resp?.data?.Status === 5){
          logout();
        }else{
          setMessage({ type: 'error', msg: resp?.data?.Message, });
        }
        
        
    })

        }

    
    }

const onChange = (e)=>{
  let {name, value} = e.target;  
  setFormData({...formData,[name]:value});
  setFormDataError({...formDataError,[name]:""});
}

const onChangeDate = (date) =>{
    console.log("date",date)
    setFormData({...formData,dob:date});
    setFormDataError({...formDataError,dob:""});
}

useEffect(() => {

  // Fetch dashboard data on mount
  const params = {
      lead_id: getStorage("lead_id") || "",
      token: getStorage("token") || "",
      mobile: getStorage("mobile") || "",
  };

  getDashboardData(params).then(resp => {
      if (resp?.data?.Status === 1) {
          const dashboardData = resp?.data?.Steps?.data || {};
          if (dashboardData) {
              // Update form data with fetched dashboard data
              setFormData(prev => ({
                  ...prev,
                  first_name: dashboardData.full_name || "",
                  dob: dashboardData.dob || "",
                  maritalStatus: dashboardData.marital_status || "",
                  email: dashboardData.email || "", 
                  gender: dashboardData.gender || "", 
            
              }));

              
          }
      } else if (resp?.data?.Status === 5) {
          logout();
      }
  });
}, [logout]);

  return (
       <BoxWrapper  className="w100" >
        <div className="formmainBox flex">
          <div className="left">
            <div className='center gap4 pointer' onClick={()=>goBack(navigate,"/my-dashboard/")} >
                <img src={arrowIcon} alt="" /> <span>Back</span>
            </div>
          </div>
          <div className="right">
            <h2>Personal Information</h2>
            <p>Share with us a bit about yourself.</p>
         
            <FormWrapper>
            <Alert setMessage={setMessage} message={message}  />
            <div className="inputBox">
           
            <Input
                  label="Your First Name"
                  name="first_name"
                  error={formDataError?.first_name}
                  onChange={onChange}
                  value={formData?.first_name}
                  required={true}
                  
                />
            <Input
                  label="Personal Email"
                  name="email"
                  error={formDataError?.email}
                  onChange={onChange}
                  value={formData?.email}
                  required={true}
                  
                />
            <Input
                  label="DOB"
                  name="dob"
                  type='date'
                  error={formDataError?.dob}
                  onChange={onChangeDate}
                  value={formData?.dob}
                  required={true}
                  
                />
                 <Select
                  label="Your Marital Status"
                  name="maritalStatus"
                  placeholder="--Select--"
                  error={formDataError?.maritalStatus}
                  onChange={onChange}
                  value={formData?.maritalStatus}
                  options={marriedOptions}
                  
                />
           
          
                <RadioButtons title='Select Gender' options={options} className="flex" value={formData.gender} error={formDataError.gender} onChange={onChange} />
              </div>
              <div>
              
              </div>
              <div className="button">
              <Button title="Continue" onClick={submit} loading={loading} />
              </div>
            </FormWrapper>
           
          </div>
        </div>

       </BoxWrapper>
    
  )
}

export default CapturPersonalInformation;