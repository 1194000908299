
import React, { useEffect } from 'react'
import { isEmpty } from '../../Utils/common';
import { AlertWrapper } from './style';

function Alert({setMessage=()=>{},message,timeOut=500}) {

    useEffect(() => {
        let timerId = null;
        if(timeOut === null) return
		if (!isEmpty(message)) {
            timerId =	setTimeout(() => {
				setMessage({});
			}, timeOut);
		}
        return clearTimeout(timerId);
	}, [message]);
  return (
  <>
    {!isEmpty(message) &&(
        <AlertWrapper className={`${message.type} ${message.place}`}>
            <span className="close" onClick={() => setMessage({})}>
                ×
            </span>
            {message.msg}
        </AlertWrapper>
    )}
  </>
  )
}

export default Alert