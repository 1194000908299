



import React, { useContext, useEffect, useRef, useState } from 'react';

import { BoxWrapper } from '../../../style';
import arrowIcon from "../../../images/arrow.png"
import { FormWrapper } from '../../../components/loan/style';
import Button from '../../../components/ui/Button';
import Alert from '../../../components/ui/Alert';

import { useNavigate } from 'react-router-dom';
import { getStorage, goBack, isEmpty, setStorage } from '../../../Utils/common';

import PictureUpload from '../../../components/PictureUpload/PictureUpload';
import { uploadProfilePhoto } from '../../../Utils/api';
import ContextDashboard from '../../../Context/ContextDashboard';



function DocumentUpload() {
    const [loading, setLoading] = useState(false);
    const [responce,setResponce] = useState({});
    const [setSide,setSetSide] = useState("front");
    const [aadharFront64,setAadharFront64] = useState("");
    const [aadharFront,setAadharFront] = useState("");
    const [aadharBack,setAadharBack] = useState("");
    
    const [aadharBack64,setAadharBack64] = useState("");
    const [message,setMessage] = useState("");

    const navigate = useNavigate();
    const {logout,profileData,getProfileDaital} = useContext(ContextDashboard);


    useEffect(()=>{
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result;
             const data = base64.split(",");
              let img = data[data.length -1];
        setAadharFront64(img)
       
      };
  
      if (aadharFront) {

      
        if(typeof aadharFront === "string"){
          const blob = new Blob([aadharFront]);
          reader.readAsDataURL(blob);
        }else{
          reader.readAsDataURL(aadharFront);
        }
      }
    },[aadharFront])

    useEffect(()=>{
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result;
             const data = base64.split(",");
              let img = data[data.length -1];
        setAadharBack64(img)
       
      };
  
      if (aadharBack) {

      
        if(typeof aadharFront === "string"){
          const blob = new Blob([aadharBack]);
          reader.readAsDataURL(blob);
        }else{
          reader.readAsDataURL(aadharBack);
        }
      }
    },[aadharBack])


  

    const submit = () =>{
     
   
      if((aadharFront === "") && (aadharFront64 === "") && (setSide === "front")){
        setMessage({ type: 'error', msg:"Please select  aadhar front side", place:"local" });
         return;
      }
        if((aadharBack === "") && (aadharBack64 === "") && (setSide === "back")){
        setMessage({ type: 'error', msg:"Please select  aadhar back side", place:"local" });
        return;
      }

        setMessage({})
    
      let ext  = "JPEG";
      if(typeof aadharFront === "object" ){
        let extArr = aadharFront.name.split('.');
        ext = extArr[extArr.length -1].toUpperCase();
      }
      
    
      const param = {
        lead_id:getStorage("lead_id") || "",
        token:getStorage("token") || "",
        ext:ext,
        password: "N/A",
     
    }

    if(setSide === "front"){
      param.docs_id = "1";
      param.file = aadharFront64;
    }else{
      param.docs_id = "2";
      param.file = aadharBack64;
    }


   
         setLoading(true);
         uploadProfilePhoto(param).then(resp=>{
      setLoading(false);
      console.log(resp?.data)
      if(resp?.data?.Status === 1){
        setResponce(resp?.data);
        setMessage({ type: 'success', msg:resp?.data?.Message, place:"globle" });
        if(setSide === "front"){
            setSetSide("back");
            setStorage("aadharSide","back")
        }else{
          getProfileDaital();
          navigate("/my-dashboard/eligibility");
        }
        
      }else if(resp?.data?.Status === 5){
        logout();
      }else{
        setMessage({ type: 'error', msg: resp?.data?.Message, });
      }
      
      
  });

      }
  
      useEffect(()=>{
        if(!isEmpty(getStorage("aadharSide")) && getStorage("aadharSide") !== "back"){
           setSetSide("back");
        }
      },[getStorage("aadharSide")])


  return (
       <BoxWrapper  className="w100" >
     

        <div className="formmainBox flex">
          <div className="left">
            <div className='center gap4 pointer' onClick={()=>goBack(navigate,"/my-dashboard/eligibility")} >
                <img src={arrowIcon} alt="" /> <span>Back</span>
            </div>
          </div>
          <div className="right">
            <h2>Upload Your aadhar</h2>
            <p>Upload your aadhar to verify your details"</p>
              
            <FormWrapper>
            <Alert setMessage={setMessage} message={message}  />
 
            <div className="inputBox">
             {setSide === "front"&& <div >
                <h2 className='subheading small'>Aadhar front side</h2>
                 <PictureUpload  setImage={setAadharFront}  image={aadharFront}/>
            
             </div>}
            {setSide === "back"&&              <div>
             <h2 className='subheading small'>Aadhar back side</h2>
                 <PictureUpload  setImage={setAadharBack}  image={aadharBack}/>
            
             </div>}
              </div>
             
              
             
              <div className="button">
              <Button title="Continue" onClick={submit} loading={loading} />
              </div>
            </FormWrapper>
           
          </div>
        </div>

       </BoxWrapper>
    
  )
}

export default DocumentUpload;