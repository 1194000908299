

import React, { useRef, useState } from 'react'
import { InputWrapper } from './style'
import DatePicker from 'react-datepicker';
import calander from '../../images/calendar.png';


import "react-datepicker/dist/react-datepicker.css";

function Input({type="text", value="", name="", label="",error="", onChange=() =>{},className="",toggle=false,...others}) {
    const [focus,setFocus] = useState("");
    const [passwordState, setPasswordState] = useState(true);
    const input = useRef();

    const focusInput = () =>{
    input.current.focus()
    }
  return (
    <InputWrapper className={className}>
    <div className={`inputField ${focus}`} onClick={()=>focusInput}>
    <label htmlFor="">{label}</label>
    {type !== "date" ?<>
    <input ref={input}
     type={type === "password"?`${passwordState?"password":"text"}`:type} value={value} name={name} onChange={onChange} {...others}  onFocus={()=>setFocus("focus")} onBlur={()=>setFocus("")} />
   {/* {(toggle && type === "password") && <div className="toggel" onClick={() =>{setPasswordState(!passwordState)}}>{passwordState ? <FaEye /> : <FaEyeSlash />}{" "}</div>} */}
   </>:
     <>
       <DatePicker selected={value}
         dateFormat="dd MMMM yyyy" 
         onChange={(date)=>onChange(date)} />
   
   <div className="icon"><img src={calander} alt="calander" /></div> 
  </>
   }
    </div>

    {(error !=="")&&<div className="error">
       {error}
    </div>}
   
    
    </InputWrapper>
  )
}

export default Input;