


import React, { useContext, useState } from 'react';

import { BoxWrapper } from '../../../style';
import arrowIcon from "../../../images/arrow.png"
import OtpInput from '../../../components/OtpInput/OtpInput';
import { FormWrapper } from '../../../components/loan/style';
import Button from '../../../components/ui/Button';
import Alert from '../../../components/ui/Alert';
import { verifyPan } from '../../../Utils/api';
import { useNavigate } from 'react-router-dom';
import { getStorage, goBack, setStorage } from '../../../Utils/common';
import { regexPan } from '../../../Utils/formValidation';
import ContextDashboard from '../../../Context/ContextDashboard';


function PanDetails() {
    const [pan, setPan] = useState(["", "", "", "","","","","","",""]);
    const [loading, setLoading] = useState(false);
    const [responce,setResponce] = useState({});

    const navigate = useNavigate();

    const {message,setMessage,logout} = useContext(ContextDashboard);
  

    const submit = () =>{
        let panData = pan?.join("").toUpperCase();
         
        
        if (!regexPan.test(panData)) {
          setMessage({ type: 'error', msg: "Please enter valid pan number", });
          return;
        }

        const param = {
            lead_id:getStorage("lead_id") || "",
            panNumber : panData,
            token:getStorage("token") || "",
        }

        setLoading(true);
        verifyPan(param).then(resp=>{
        setLoading(false);
        console.log(resp?.data)
        if(resp?.data?.Status === 1){
          setResponce(resp?.data);
          setMessage({ type: 'success', msg:resp?.data?.Message, place:"globle" });
          navigate("/my-dashboard/captur-address")
        }else if(resp?.data?.Status === 5){
          logout();
        }else{
          setMessage({ type: 'error', msg: resp?.data?.Message, });
        }
        
        
    })

    }


 

  return (
       <BoxWrapper  className="w100" >
        <div className="formmainBox flex">
          <div className="left">
            <div className='center gap4 pointer' onClick={()=>goBack(navigate,"/my-dashboard/")} >
                <img src={arrowIcon} alt="" /> <span>Back</span>
            </div>
          </div>
          <div className="right">
            <h2>PAN Authentication</h2>
            <p>Please enter your PAN Card number. Your identity is secure with us.</p>
         
            <FormWrapper>
            <Alert setMessage={setMessage} message={message}  />
            <div className="inputBox panBox">
                <OtpInput
                  type="text"
                  setOtp={setPan}
                  otp={pan}
                  isDisable={false}
                  placeholder="*"
                  number={false}
                  
                  
                />
              </div>
              <div className="button">
              <Button title="Continue" onClick={submit} loading={loading} />
              </div>
            </FormWrapper>
           
          </div>
        </div>

       </BoxWrapper>
    
  )
}

export default PanDetails;