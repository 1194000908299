import React, { useContext, useEffect, useState } from "react";
import { LoanCalculatorWrapper } from "./style";
import img from "../../images/loan_calculation.png";
import RangeSlider from "../RangeSlider/RangeSlider";
import principalIcon from '../../images/Principal.svg';
import emiIcon from '../../images/EMI.svg';
import paybleIcon from '../../images/Total payble.svg';
import banner from "../../images/3.jpg";
import { getStorage } from "../../Utils/common";
import Button from "../ui/Button";
import ContextDashboard from "../../Context/ContextDashboard";
import { calculateLoan } from "../../Utils/api";
import { useNavigate } from "react-router-dom";
import Select from '../../components/ui/Select';

const initialData = {
  loanPurpose:""
}

const options =[
  {
      label:"Travel",
      value:"Travel",
      name:"loanPurpose",
  },
  {
      label:"Medical",
      value:"Medical",
      name:"loanPurpose",
  },
  {
    label:"Shopping",
    value:"Shopping",
    name:"loanPurpose",
},
   
]
function LoanCalculator() {
  const [principal, setPrincipal] = useState(5000);
  const [tenure, setTenure] = useState(30);
  const [emi, setEmi] = useState(0);
  const [loading,setLoading] = useState(false);
  const [formData, setFormData] = useState(initialData);
  const [formDataError, setFormDataError] = useState(initialData);
    
 
 


  const {message,setMessage,logout,getProfileDaital} = useContext(ContextDashboard);
  const navigate = useNavigate();

  const submit = () => {
    const param = {
      lead_id: getStorage("lead_id") || "",
      token: getStorage("token") || "",
      tenure: tenure,
      purpose: formData.loanPurpose, 
      loan_amount: principal,
    };
  
    setLoading(true);
    calculateLoan(param).then(resp => {
      setLoading(false);
  
      if (resp?.data?.Status === 1) {
        setMessage({ type: 'success', msg: resp?.data?.Message, place: "globle" });
        getProfileDaital();
        navigate("/my-dashboard/about-your-company");
      } else if (resp?.data?.Status === 5) {
        logout();
      } else {
        setMessage({ type: 'error', msg: resp?.data?.Message });
      }
    });
  }
  


const onChange = (e)=>{
  let {name, value} = e.target;  
  setFormData({...formData,[name]:value});
  setFormDataError({...formDataError,[name]:""});
}

  useEffect(() =>{

    let si = (principal * tenure)/100;
    setEmi(si);

  },[principal,tenure]);
  return (
    <LoanCalculatorWrapper className="flex">
      <div className="left">
        <div>
     
          <p>
            WE HAVE CALCULATED YOUR LOAN ELIGIBILITY BASED ON THE GIVEN DETAILS
            PLEASE CHOOSE THE LOAN AMOUNT AND TENURE AS PER YOUR REQUIREMENTS.
          </p>

         {/* <div className="img">
         <img src={banner} alt="img"  />
         </div>
         */}
        </div>
      </div>
      <div className="right">
        {/* <div className="box">
                <h2>Purpose of Loan</h2>
                <div>
                <Select
                  label="Residence Type"
                  name="residenceType"
                  placeholder="Enter Mobile"
                  error={formDataError?.residenceType}
                  onChange={onChange}
                  value={formData?.residenceType}
                  options={options}
                  
                />
                </div>

            </div> */}

        <div className="box">
          <div className="flex space-between">
            <h2>Purpose of Loan</h2>
            {/* <span className="value"> Interest rate fixed 1%</span> */}
          </div>
          <div className="center">
          <Select
                  className="selectLoanPurpose"
                  name="loanPurpose"
                  placeholder="loan purpose"
                  error={formDataError?.loanPurpose}
                  onChange={onChange}
                  value={formData?.loanPurpose}
                  options={options}
                  
                />
          </div>
        </div>
        <div className="box">
          <div className="flex space-between">
            <h2>Principal</h2>
            <span className="value">₹ {principal}</span>
          </div>
          <div className="center">
            <RangeSlider
              min="5000"
              step="1000"
              max="100000"
              value={principal}
              setValue={setPrincipal}
            />
          </div>
        </div>
        <div className="box">
          <div className="flex space-between">
            <h2>Tenure</h2>
            <span className="value"> Tenure fixed for {tenure} Days</span>
          </div>
          <div className="center">
            <RangeSlider 
              min="3"
              step="1"
              max="30"
              value={tenure}
              setValue={setTenure}
            />
          </div>
        </div>

        <div className="cards">
            <div className="card">
                <div className="img">
                    <img src={principalIcon} alt="icon" />
                </div>
                <div className="desc">
                <h3>PRINCIPAL</h3>
                <h2>₹ {principal}</h2>
                </div>
            </div>
            <div className="card">
                <div className="img">
                    <img src={emiIcon} alt="icon" />
                </div>
                <div className="desc">
                <h3>EMI</h3>
                <h2>₹ {emi}</h2>
                </div>
            </div>
            <div className="card">
                <div className="img">
                    <img src={paybleIcon} alt="icon" />
                </div>
                <div className="desc">
                <h3>TOTAL PAYABLE</h3>
                <h2>₹ {Number(principal) + Number(emi)}</h2>
                </div>
            </div>
          </div>
          <Button title={"Accept"} onClick={submit} loading={loading} />
          
      </div>
    </LoanCalculatorWrapper>
  );
}

export default LoanCalculator;