import React, { useContext } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import { ModalWrapper } from "./style";
import ContextDashboard from '../../Context/ContextDashboard';

const Modal = ({ onClose, onConfirm, msg }) => {
  const navigate = useNavigate();
  const{eligibilityStatus}=useContext(ContextDashboard); // Initialize the navigate function

  // Function to handle the "Process" button click
  const handleProcessClick = () => {
    navigate('/my-dashboard/eligibility'); // Redirect to the eligibility URL
  };

  // Function to handle the "OK" button click
  const handleOkClick = () => {
    if(eligibilityStatus === "ELIGIBLE"){
      handleProcessClick(); // Redirect if eligible
    } else {
      onClose(); // Close modal if not eligible
    }
  };

  return (
    <ModalWrapper>
      <div className="modal-box">
        <h2>{msg}</h2>
        {/* <p>This action cannot be undone.</p> */}
        <div className="modal-buttons">
          <button onClick={handleOkClick}>OK</button>
          {msg === "You are eligible for loan" && (
            <button onClick={handleOkClick}>Process</button>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default Modal;
