import Request from "./Request";
import urls from "./urls";

const apiRequest = new Request(
  () => {},
  () => {},
  () => {}
);


export const sendotpForLogin = (params) => {
	return apiRequest.post(`${urls.API_BASEPATH}${urls.loan.SEND_OTP}`, params);
};
export const verifyotpForLogin = (params) => {
	return apiRequest.post(`${urls.API_BASEPATH}${urls.loan.VERIFY_OTP}`, params);
};
export const verifyPan = (params) => {
	return apiRequest.post(`${urls.API_BASEPATH}${urls.loan.VERIFY_PAN}`, params);
};
export const getDashboardData = (params) => {
	return apiRequest.post(`${urls.API_BASEPATH}${urls.loan.GET_DASHBOARD}`, params);
};
export const savePerssonalDetails = (params) => {
	return apiRequest.post(`${urls.API_BASEPATH}${urls.loan.PERSONAL_DETAILS}`, params);
};
export const savePerssonalAddress = (params) => {
	return apiRequest.post(`${urls.API_BASEPATH}${urls.loan.ADDRESS_DETAILS}`, params);
};
export const getStateCityPincode = (params) => {
	return apiRequest.post(`${urls.API_BASEPATH}${urls.loan.STATE_CITY_PINCODE}`, params);
};
export const getIncomeDetails = (params) => {
	return apiRequest.post(`${urls.API_BASEPATH}${urls.loan.INCOME_DETAILS}`, params);
};
export const uploadProfilePhoto = (params) => {
	return apiRequest.post(`${urls.API_BASEPATH}${urls.loan.UPLOAD_PROFILE_PIC}`, params);
};
export const ckeckEligibility = (params) => {
	return apiRequest.post(`${urls.API_BASEPATH}${urls.loan.CHECK_ELIGIBILITY}`, params);
};
export const aboutCompany = (params) => {
	return apiRequest.post(`${urls.API_BASEPATH}${urls.loan.ABOUT_COMPANY}`, params);
};
export const calculateLoan = (params) => {
	return apiRequest.post(`${urls.API_BASEPATH}${urls.loan.CALCULATE_LOAN}`, params);
};

