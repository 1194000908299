import React, { useContext, useEffect, useState } from "react";
import ProfileHeader from "./ProfileHeader";
import Button from "../../../components/ui/Button";
import DetailBox from "./DetailBox";
import { ProfilePreviewWrapper } from "./style";
import { useNavigate } from "react-router-dom";
import ContextDashboard from "../../../Context/ContextDashboard";
import { getStorage, isEmpty } from "../../../Utils/common";
import { ckeckEligibility, getStateCityPincode } from "../../../Utils/api";
import Modal from "../../../components/Modal/Modal";

function ProfilePreview() {
  const navigate = useNavigate();
  const [state,setState] = useState("");
  const [city,setCity] = useState("");
  const [modelOpen,setModelOpen] = useState(false);
  const [loading,setLoading] = useState(false);
  const [responce,setResponce] = useState(false);
  const{eligibilityStatus}=useContext(ContextDashboard);

  const { message, setMessage, profileData,logout,getProfileDaital } = useContext(ContextDashboard);

  const redirect = (link) => {
    navigate(link);
  };

  const StateCityList = (type="getstate",id=null)=>{
    const param ={
      apiname:type,
    }
    if(!isEmpty(id)){
      param.id = id;
    }
    getStateCityPincode(param).then((resp) =>{
  
        if(resp?.data?.data){
          
           if(type === "getstate"){
            const data = resp.data.data.map((value) =>{
              if(value.id === profileData.state_id){
                setState(value.name);
                return;
                  }
            })
       
           }else if(type === "getcity"){
            resp.data.data.map((value) =>{
                if(value.m_city_id === profileData.city_id){
              setCity(value.m_city_name);
              return;
                }
              
            })
         
           } 
           
        }
    }) 
  }

  useEffect(() =>{
    if(isEmpty(profileData)) return;
    StateCityList("getstate");
    StateCityList("getcity",profileData.state_id);
  },[profileData]);



  const submit = () =>{
     
    const param = {
      lead_id:getStorage("lead_id") || "",
      token:getStorage("token") || "",
  }


       setLoading(true);
       ckeckEligibility(param).then(resp=>{
    setLoading(false);
    if(resp?.data?.Status === 1){
      setModelOpen(true);
      getProfileDaital();
      setResponce(resp?.data?.Message);
      
    }else if(resp?.data?.Status === 5){
      logout();
    }else{
        setModelOpen(true);
        setResponce(resp?.data?.Message);
    //   setMessage({ type: 'error', msg: resp?.data?.Message, place:"globle" });
    }
    
    
})


    


}


useEffect(() =>{
    if(isEmpty(profileData)) return;
    getProfileDaital()
},[]);


  return (
    <ProfilePreviewWrapper>
      <ProfileHeader>
        <Button title="Check Eligibility" onClick={submit} loading={loading}/>
      </ProfileHeader>
      <div className="detailBox">
        
        <DetailBox
          heading="Basic Details"
          onClock={
            eligibilityStatus === 'ELIGIBLE' 
              ? () => navigate("/my-dashboard/captur-personal-information", { state: { action: 'update' } })
              : undefined
          }
        >
          <table>
            <tr>
              <td>Your Name</td>
              <td>{profileData.full_name || profileData.short_name || "NA"}</td>
            </tr>
            <tr>
              <td>Gender</td>
              <td>{profileData?.gender || "NA"}</td>
            </tr>
            <tr>
              <td>DOB</td>
              <td>{profileData?.dob || "NA"}</td>
            </tr>
            <tr>
              <td>Marital Status</td>
              <td>
                {profileData?.marital_status
                  ? profileData?.marital_status === "1"
                    ? "Single"
                    : "Married"
                  : "Divorced"}
              </td>
            </tr>
            <tr>
              <td>Personal Email</td>
              <td>
                {profileData?.email ? profileData?.email.toLowerCase() : "NA"}
              </td>
            </tr>
          </table>
        </DetailBox>

        <DetailBox
          heading="Residence Address"
          onClock={() => {
            navigate("/my-dashboard/captur-address",{ state: { action: 'update' }});
          }}
        >
          <table>
            <tr>
              <td>Address</td>
              <td>{profileData?.current_house || "NA"}</td>
            </tr>
            <tr>
              <td>Residence Type</td>
              <td>{profileData?.current_residence_type || "NA"}</td>
            </tr>
            <tr>
              <td>Current Locality</td>
              <td>{profileData?.current_locality || "NA"}</td>
            </tr>
            <tr>
              <td>Landmark</td>
              <td>{profileData?.current_landmark || "NA"}</td>
            </tr>
            <tr>
              <td>State</td>
              <td>{state || "NA"}</td>
            </tr>
            <tr>
              <td>City</td>
              <td>{city || "NA"}</td>
            </tr>
            <tr>
              <td>Pincode</td>
              <td>{profileData?.pincode || "NA"}</td>
            </tr>
          </table>
        </DetailBox>

        <DetailBox
          heading="Income Details"
          onClock={() => {
            navigate("/my-dashboard/captur-income-details",{ state: { action: 'update' }});
          }}
        >
    
          <table>
            <tr>
              <td>Employment Type</td>
              <td>{profileData?.employee_type || "NA" }</td>
            </tr>
            <tr>
              <td>Organization Name</td>
              <td>{profileData?.company_name || "NA"}</td>
            </tr>
            <tr>
              <td>Monthy Income</td>
              <td>{profileData?.monthly_salary || "NA"}</td>
            </tr>
            <tr>
              <td>Mode Income Received</td>
              <td>{profileData?.salary_mode || "NA"}</td>
            </tr>
          </table>
        </DetailBox>
      </div>
     {modelOpen && <Modal onClose={()=>setModelOpen(false)} msg={responce} onConfirm={()=>navigate("/my-dashboard/eligibility")} />}
    </ProfilePreviewWrapper>
  );
}

export default ProfilePreview;
