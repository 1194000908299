

import React from 'react'
import { BoxWrapper } from '../../../style'
import { goBack } from '../../../Utils/common';
import arrowIcon from "../../../images/arrow.png";
import congratulations from "../../../images/congratulations.png";
import { useNavigate } from 'react-router-dom';

function ThankYou() {
  const navigate = useNavigate();
  const divStyle = {
    textAlign : 'center',
    display: 'block',
    width: '100%'
  };
  const imgStyle = {
    maxWidth: '30%',
    height: 'auto'
  };
return (

  <BoxWrapper className="w100 gray" >
    <div className="formmainBox flex">
      <div className="left">
        <div className='center gap4 pointer' onClick={() => goBack(navigate, "/my-dashboard/eligibility")}>
          <img src={arrowIcon} alt="" /> <span>Back</span>
        </div>
      </div>
      <div className="right">
      </div>
      <div style={divStyle}>
        <img style={imgStyle} src={congratulations} alt="" />
        <h2 style={{ fontSize: '25px', color: '#082654', lineHeight: '40px', fontWeight: '600' }}>
          Your Loan Application Has Been Submitted Successfully.
          <br />
          We Will Connect With You Soon.
        </h2>

      </div>
    </div>

  </BoxWrapper>
)
}

export default ThankYou;