import React, { useContext, useEffect, useRef, useState } from "react";

import { BoxWrapper } from "../../../style";
import arrowIcon from "../../../images/arrow.png";
import { FormWrapper } from "../../../components/loan/style";
import Button from "../../../components/ui/Button";
import Alert from "../../../components/ui/Alert";

import { useLocation, useNavigate } from "react-router-dom";
import { getStorage, goBack, isEmpty } from "../../../Utils/common";

import PictureUpload from "../../../components/PictureUpload/PictureUpload";

import ContextDashboard from "../../../Context/ContextDashboard";
import { uploadProfilePhoto } from "../../../Utils/api";

function PanUpload() {
  const [loading, setLoading] = useState(false);
  const [responce, setResponce] = useState({});
  const [image, setImage] = useState("");
  const [base64, setBase64] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  const { message, setMessage, logout,  getProfileDaital } =
    useContext(ContextDashboard);

  useEffect(() => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64 = reader.result;
      const data = base64.split(",");
      let img = data[data.length - 1];
      setBase64(img);
    };

    if (image) {
      if (typeof image === "string") {
        const blob = new Blob([image]);
        reader.readAsDataURL(blob);
      } else {
        reader.readAsDataURL(image);
      }
    }
  }, [image]);

  const submit = () => {
    if (image === "" && base64 === "") {
      setMessage({
        type: "error",
        msg: "Please select  pan image",
        place: "local",
      });
      return;
    } else {
      setMessage({});
    }
    let ext = "JPEG";
    if (typeof image === "object") {
      let extArr = image.name.split(".");
      ext = extArr[extArr.length - 1].toUpperCase();
    }

    const param = {
      lead_id: getStorage("lead_id") || "",
      token: getStorage("token") || "",
      file: base64,
      ext: ext,
      password: "N/A",
      docs_id: "4",
    };

    setLoading(true);
    uploadProfilePhoto(param).then((resp) => {
      setLoading(false);
      console.log(resp?.data);
      if (resp?.data?.Status === 1) {
        setResponce(resp?.data);
        setMessage({
          type: "success",
          msg: resp?.data?.Message,
          place: "globle",
        });
        getProfileDaital();
        navigate("/my-dashboard/bank-upload");
      } else if (resp?.data?.Status === 5) {
        logout();
      } else {
        setMessage({ type: "error", msg: resp?.data?.Message });
      }
    });
  };

  

  return (
    <BoxWrapper className="w100">
      <div className="formmainBox flex">
        <div className="left">
          <div
            className="center gap4 pointer"
            onClick={() => goBack(navigate, "/my-dashboard/eligibility")}
          >
            <img src={arrowIcon} alt="" /> <span>Back</span>
          </div>
        </div>
        <div className="right">
          <h2>Upload Your PAN</h2>
          <p>Upload your PAN to verify your details</p>

          <FormWrapper>
            <Alert setMessage={setMessage} message={message} />

            <div className="inputBox">
              <PictureUpload setImage={setImage} image={image} />
            </div>

            <div className="button">
              <Button
                title={state?.action ? "Update" : "Continue"}
                onClick={submit}
                loading={loading}
              />
            </div>
          </FormWrapper>
        </div>
      </div>
    </BoxWrapper>
  );
}

export default PanUpload;